
































































import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import RequisitionsTable from '@/components/RequisitionsTable.vue'
import { ApiResponse, Pagination, Requisition } from '@/interfaces'
import { mapState } from 'vuex'
import QuickNav from '@/components/QuickNav.vue'

interface SortObject {
  order: string
  prop: string
}

interface Parameter {
  search?: string | null
  page?: string | null
}

export default Vue.extend({
  components: {
    Nav,
    RequisitionsTable,
    QuickNav,
  },
  data() {
    return {
      requisitions: [] as Requisition[],
      search: '',
      page: '1',
      activePage: 1,
      itemsTotal: 0,
      numPages: 1,
      orderBy: null as null | string,
      order: null as null | string,
      busy: false,
    }
  },
  computed: {
    ...mapState(['user', 'scrollPosition']),
    /*
  statusFilters() {
    return [
      { text: 'En cours', value: 'en_cours' },
      { text: 'Clos', value: 'clos' },
    ]
  },
  */
  },
  mounted() {
    if (this.$route.query.search && this.$route.query.search !== null && this.$route.query.search !== '') {
      this.search = this.$route.query.search as string
    }

    if (this.$route.query.page) {
      this.page = this.$route.query.page as string
    }

    if (this.$route.query.search) {
      this.search = this.$route.query.search as string
    }

    if (this.$route.query.orderby) {
      this.orderBy = this.$route.query.orderby as string
    }

    if (this.$route.query.order) {
      this.order = this.$route.query.order as string
    }

    this.getRequisitions()
  },
  methods: {
    formatDate(str: string, hours = false): string {
      const date = Date.parse(str)
      if (!isNaN(date)) {
        if (hours) {
          const day = new Intl.DateTimeFormat('fr-FR', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
          }).format(date)
          const hour = new Intl.DateTimeFormat('fr-FR', {
            hour: 'numeric',
            minute: 'numeric',
          }).format(date)

          return `${day} à ${hour}`
        } else {
          return new Intl.DateTimeFormat('fr-FR').format(date)
        }
      } else {
        return '-'
      }
    },
    updateRoute(resetPage = false) {
      if (resetPage) {
        this.page = '1'
      }

      // Setting query URL
      const query = {
        search: this.search,
        page: this.page,
        orderby: this.orderBy,
        order: this.order,
      }

      this.$router.push({ query: Object.assign({}, this.$route.query, query) }).catch(() => {
        // This empty catch method is here to avoid the "Avoided redundant navigation" error
      })
    },
    changePage(page: string) {
      this.page = page
      this.updateRoute()
    },
    sortChange(sort: SortObject) {
      this.order = null
      this.orderBy = null

      switch (sort.order) {
        case 'ascending':
          this.order = 'ASC'
          break

        case 'descending':
          this.order = 'DESC'
          break

        default:
          this.order = 'DESC'
          break
      }

      this.orderBy = sort.prop

      // Setting query URL
      const query = {
        search: this.search,
        page: this.page,
        orderby: this.orderBy,
        order: this.order,
      }

      this.$router.push({ query: Object.assign({}, this.$route.query, query) }).catch(() => {
        // This empty catch method is here to avoid the "Avoided redundant navigation" error
      })
    },
    getRequisitions() {
      if (!this.busy) {
        this.busy = true
        this.requisitions = [] as Requisition[]

        let params = {} as Parameter
        if (this.search) {
          params.search = this.search
        } else {
          delete params.search
        }

        // Setting query URL
        this.$router
          .push({
            query: Object.assign({}, this.$route.query, params),
          })
          .catch(() => {
            // This empty catch method is here to avoid the "Avoided redundant navigation" error
          })

        const loading = this.$loading({
          target: '#requisitionsTable',
          text: 'Chargement des données...',
        })

        this.$api
          .get('/requisitions', {
            params: {
              search: this.search,
              page: this.page,
              orderby: this.orderBy,
              order: this.order,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.requisitions = apiResponse.data.requisitions

            const pagination = apiResponse.data.pagination as Pagination

            this.activePage = pagination.current
            this.itemsTotal = pagination.totalCount
            this.numPages = pagination.pageCount
          })
          .finally(() => {
            loading.close()
            this.busy = false

            // Automatically scroll to stored scroll position
            window.setTimeout(() => {
              window.scrollTo({ top: parseInt(this.scrollPosition) })
            }, 400)
          })
      }
    },
  },
})
